// Removes the default bullets, margins and padding from a list element
@mixin u-list-reset {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.u-list-reset {
  @include u-list-reset();
}
