.guide-header {
  height: $header-height;
  position: sticky;
  top: 0;
}

.guide-nav {
  height: calc(100vh - #{$header-height});
  position: sticky;
  left: 0;
  top: $header-height;
  width: $sidebar-width;
}

main {
  width: calc(100% - #{$sidebar-width});
}
