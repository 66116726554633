$display-options: block, inline-block, flex;

@mixin u-display($value) {
  display: #{$value};
}

.u-block {
  @include u-display(block);
}

.u-inline-block {
  @include u-display(inline-block);
}

.u-flex {
  @include u-display(flex);
}
