.guide-code-toggle {
  position: fixed;
  top: 0;
  right: $spacing-unit;
  text-align: right;
  width: 200px;
  &__label, .guide-code__buttons {
    display: inline-block;
  }
  &__label {
    color: $color-regent-gray;
    margin-right: $spacing-unit-sm;
    text-transform: uppercase;
  }
  .guide-code__label {
    border-top: 1px solid $color-indigo-lightest;
    top: unset;
    left: unset;
    &:first-child {
      border-left: 1px solid $color-indigo-lightest;
    }
    &:hover,  &:focus {
      border-color: $color-indigo;
      color: $color-indigo;
    }
    &.is-selected {
      background-color: $color-indigo;
      color: white;
      cursor: default;
    }
  }
}
