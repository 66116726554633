.guide-content {
	nav {
		margin-top: $spacing-unit-lg;
		li {
			@media (max-width: 863px) {
				+ li {
					margin-top: $spacing-unit-sm;
				}
			}
			@media (min-width: 864px) and (max-width: 1279px) {
				display: inline-block;
				+ li {
					margin-left: $spacing-unit;
				}
			}
			@media (max-width: 1279px) {
				&.topLink { display: none; }
			}
		}
		@media (min-width: 1280px) {
			background-color: $color-concrete;
			position: fixed;
			top: $spacing-unit-lg;
			left: $sidebar-width + $content-width + $spacing-unit-lg;
			li + li {
				margin-top: $spacing-unit-sm;
			}
		}

		@media (min-width: 1340px) {
			left: $sidebar-width + $content-width + $spacing-unit-xl;
		}

		a {
			border-bottom: 1px solid $color-lux-purple;
			font-weight: 600;
			padding: 2px 4px;
			position: relative;
			text-decoration: none;
			transition: color $transition-timing ease-in;
			&::before {
				background-color: $color-lux-purple;
				content: "";
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				transform: scaleY(0);
				transform-origin: bottom center;
				transition: transform $transition-timing ease-in;
				width: 100%;
				@include z-index(bottom);
			}
			&:link,
			&:visited {
				color: black;
			}
			&:hover,
			&:focus {
				color: white;
				&::before {
					transform: scaleY(1);
				}
			}
		}
	}
  h2 {
    margin-top: 0;
    margin-bottom: $spacing-unit;
    padding-top: $spacing-unit-lg;
    width: 100%;
  }
  h3 {
    margin-top: $spacing-unit-lg;
    margin-bottom: $spacing-unit;
  }
}
