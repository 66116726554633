.guide-content.has-code .guide-example,
.guide-code {
  border: 1px solid $color-indigo-lightest;
  padding: $spacing-unit;
  &__label {
    color: $color-indigo-lightest;
    display: inline-block;
    margin: 0;
    position: relative;
    text-transform: uppercase;
    top: (-$spacing-unit-sm);
    left: (-$spacing-unit);
  }
}

.guide-content:not(.has-code) {
  .guide-example__label {
    display: none;
  }
}

.guide-content.has-code .guide-example{
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: $content-width;
  min-height: 170px;
  position: relative;
  &__label {
    border-right: 1px solid $color-indigo-lightest;
    border-bottom: 1px solid $color-indigo-lightest;
    padding: $spacing-unit-xxs $spacing-unit-xs;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.guide-example {
  .box {
    background-color: $color-loblolly-light;
    border: 1px solid $color-ebony-clay-lightest;
    text-align: center;
  }
  &__padding {
    align-items: center;
    flex-wrap: wrap;
    .box {
      margin-bottom: $spacing-unit-xs;
      margin-right: $spacing-unit-xs;
      width: max-content;
      *, > code {
        transition: all $transition-timing ease-in;
      }
      @include hover-state {
        background-color: $color-apricot-peach-lightest;
        > code {
          background-color: white;
        }  
      }
    }
  }
  &__margin {
    .wrapper, .wrapper--fw {
      overflow: overlay;
      transition: all $transition-timing ease-in;
      @include hover-state {
        background-color: $color-apricot-peach-lightest;
      }
      .wrapper {
        width: max-content;
      }
      &--fw {
        width: 100%;
        .box {
          width: max-content;
        }
      }
    }
    .box {
      pointer-events: none;
    }
  }
  &__zindex {
    .box {
      align-items: center;
      display: flex;
      height: 120px;
      justify-content: center;
      opacity: 0.6;
      position: relative;
      transition: all $transition-timing ease-in;
      width: 120px;
      @include hover-state {
        background-color: $color-apricot-peach-lightest;
        opacity: 1;
      }
      @for $i from 2 through length($z-index) {
        &:nth-child(#{$i}) {
          margin-left: $spacing-unit * $i;
          margin-top: -$spacing-unit;
        }
      }

    }
  }
}
