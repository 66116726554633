.guide-nav {
  background-color: $color-lux-purple;
  color: $color-concrete;
  padding: $spacing-unit;
  a {
    border-bottom: 1px solid rgba(white, 0.5);
    color: white;
    padding-bottom: $spacing-unit-xxs;
    text-decoration: none;
    @include hover-state {
      border-bottom-color: white;
      border-bottom-style: solid;
    }
  }
  ul {
    margin-bottom: $spacing-unit-lg;
  }
  ul ul li {
    margin-bottom: $spacing-unit-sm;
  }
}
