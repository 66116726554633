.lux-form {
	.form-label {
		> .form-input,
		+ .form-input {
			margin-top: $spacing-unit-xs;
		}
	}

	.form-input {
		background: white;
		border: 1px solid $color-mystic-dark;
		border-radius: 3px;
		box-shadow: 0 2px 3px 0 rgba(black, 0.02) inset, 0 1px 2px 0 rgba(black, 0.09) inset;
		color: $color-ebony-clay;
		display: block;
		max-width: 100%;
		padding: $spacing-unit-xxs $spacing-unit-xs;
		width: 280px;
		::-moz-placeholder {
			opacity: 1; // overriding default FF opacity
		}
		&::placeholder {
			color: $color-pale-sky;
			font-size: 0.9rem;
		}
		&:focus {
			border-color: $color-lumenblue;
			box-shadow: 0 1px 0 0 rgba($color-ebony-clay-darkest, 0.05);
		}
		&[disabled] {
			opacity: 0.65;
		}
		&--fw {
			width: 100%;
		}
		&.has-icon {
			background-repeat: no-repeat;
		}
		&.icon--prefix {
			background-position: 3% 48%;
			padding-left: $spacing-unit + $spacing-unit-xs;
		}
		&.icon--suffix {
			background-position: 97% 48%;
		}
		&.icon--calendar {
			background-image: svg(
				'<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path fill="#FFF" d="M2 5h20v17H2z"/><path d="M7 2v1.83h10.048V2h1.113v1.83H22V22H2V3.83h3.887V2H7zm13.81 7.037H3.19v11.945h17.62V9.037zM7 17v2H5v-2h2zm4 0v2H9v-2h2zm4 0v2h-2v-2h2zm-8-3v2H5v-2h2zm4 0v2H9v-2h2zm4 0v2h-2v-2h2zm4 0v2h-2v-2h2zM7 11v2H5v-2h2zm4 0v2H9v-2h2zm4 0v2h-2v-2h2zm4 0v2h-2v-2h2zM5.839 5.006H3.19V7.86h17.62V5.006H18.16v1.11h-1.113v-1.11H6.952v1.11H5.839v-1.11z" fill="#212B36"/></g></svg>'
			);
		}
	}

	.has-error .form-input {
		background-color: mix($color-error, white, 6%);
		border-color: $color-error;
		&:focus {
			box-shadow: inset 0 0 0 1px rgba($color-pale-sky, 0.25), inset 0 1px 2px 0 rgba($color-pale-sky, 0.2);
		}
	}
}
