.guide-code {
  display: none;
  .guide-content.is-expanded & {
    border-top: 0;
    display: block;
    max-width: $content-width;
    min-height: 193px;
    position: relative;
    padding-top: $spacing-unit;
  }
}

.guide-code__buttons {

  .button-group {
    position: relative;
    top: (-$spacing-unit-sm);
  }
  button {
    @include button-reset();
  }
  .guide-code__label {
    border-bottom: 1px solid $color-indigo-lightest;
    border-right: 1px solid $color-indigo-lightest;
    padding: $spacing-unit-xxs $spacing-unit-xs;
    transition: all $transition-timing ease-in;
    &--copy {
      border-right: 0;
      border-left: 1px solid $color-indigo-lightest;
      position: absolute;
      top: 0;
      right: 0;
      left: unset;
    }
    &.is-selected,
    &:hover,
    &:focus {
      background-color: white;
    }
    &:active {
      background-color: $color-indigo-lightest;
      color: white;
    }
  }
}
