*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
  scroll-padding-top: $header-height;
}

body {
	background-color: $color-concrete;
	color: $color-ebony-clay;
	font-family: $font-franklin;
	margin: 0;
}

main {
	padding: $spacing-unit;
}

main {
	a:not(.button) {
		&:link,
		&:visited {
			color: $color-blue-gem-darker;
		}
		&:hover,
		&:active,
		&:focus {
			color: $color-blue-gem-light;
		}
	}
}

a {
  &:link,
  &:visited {
    color: $color-blue-gem-darker;
  }
  &:hover,
  &:active,
  &:focus {
    color: $color-blue-gem-light;
  }
}

