.changelog-wrapper {
  max-width: $content-width;
  h2 {
    color: $color-indigo-darkest;
  }
  code {
    color: $color-purple-heart-lighter;
    font-size: 1.1rem;
  }
}
