body {
	background: white;
	color: $color-ebony-clay;
	font-family: $font-franklin;
}
*,
*::before,
*::after {
	box-sizing: border-box;
}
button {
	cursor: pointer;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	text-align: inherit;

	&[disabled] {
		cursor: default;
	}
}
