// @TODO Come back and provide some more detail about what's going on here
$shade-options: (
  "lighten": white,
  "darken": black
);
$shade-values: (
  "some": 12%,
  "more": 24%,
  "max": 36%
);

@function mix-color($color, $shade, $value) {
  @if map-has-key($shade-options, $shade) {
    @if map-has-key($shade-values, $value) {
      @return mix(map-get($shade-options, $shade), $color, map-get($shade-values, $value));
    } @else {
      @error "`#{$value}` is an unknown value. All possible values are in the $shade-values list.";
    }
  } @else {
    @error "`#{$shade}` is an unknown value. All possible values are in the $shade-options list.";
  }
}
