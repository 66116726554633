// Visually hide an element while allowing it to still be utlized by a screenreader
@mixin u-sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  margin: -1px;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

.u-sr-only {
  @include u-sr-only();
}
