.lux-form {
  // some sensible defaults or resets for all Lux form components
  #{$all-text-inputs}, textarea, select {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-align: inherit;

    &:focus {
      outline: none;
    }
  }
}
