// Removes default styles from the button element
@mixin button-reset {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
  text-align: inherit;

  &[disabled] {
    cursor: default;
  }
}

.u-button-reset {
  @include button-reset();
}
