.icon {
  height: 32px;
  width: 32px;
}

.icon-spinner {
  &__arc {
    animation: rotate360 0.8s linear infinite;
    transform-origin: center;
  }
  &--primary {
    color: $color-lumenblue;
  }
}
