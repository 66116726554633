$z-index: (
  top: 999,
  high: 800,
  mid: 500,
  low: 100,
  lower: 1,
  bottom: -1
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}

.u-z-bottom {
  @include z-index(bottom);
}

.u-z-lower {
  @include z-index(lower);
}

.u-z-low {
  @include z-index(low);
}

.u-z-mid {
  @include z-index(mid);
}

.u-z-high {
  @include z-index(high);
}

.u-z-top {
  @include z-index(top);
}
