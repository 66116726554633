.button {
	background: linear-gradient(180deg, white 0%, $color-athens-gray 100%);
	border: 1px solid $color-loblolly;
	border-radius: 3px;
	box-shadow: 0 1px 0 0 rgba($color-ebony-clay, 0.05);
	color: $color-ebony-clay;
	font-size: 0.9rem;
	line-height: 1;
	padding: $spacing-unit-xs $spacing-unit-sm;
	text-align: center;

	&::-moz-focus-inner {
		border: none; // removes dotted line on focus
	}

	&:not([disabled]) {
		&:hover {
			background: linear-gradient(180deg, white 0%, $color-mystic-lightest 100%);
		}
		&:active {
			background: $color-mystic-lightest;
			box-shadow: inset 0 1px 1px 0 rgba(99, 115, 129, 0.61), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2);
			box-shadow: inset 0 1px 1px 0 rgba($color-pale-sky, 0.6), inset 0 1px 4px 0 rgba($color-pale-sky, 0.2);
		}
	}

	&:focus {
		outline: none;
	}
	&:not(:active):focus {
		border-color: $color-lumenblue;
	}
	&[disabled] {
		background: $color-gallery;
		color: $color-pale-sky;
		opacity: 0.65;
	}

	&--primary {
		background: $color-lumenblue;
		background-image: linear-gradient(180deg, rgba($color-ebony-clay, 0) 0%, rgba($color-ebony-clay, 0.04) 100%);
		border-color: $color-lumenblue-darkest;
		box-shadow: 0 1px 0 0 rgba($color-ebony-clay, 0.08), inset 0 1px 0 1px rgba(white, 0.08);
		color: white;
		&:not([disabled]) {
			&:hover {
				background: $color-lumenblue-dark;
				box-shadow: 0 1px 0 0 rgba($color-ebony-clay, 0.08), inset 0 1px 0 1px rgba(white, 0.08);
			}
			&:active {
				background: $color-lumenblue-darker;
				box-shadow: inset 0 1px 1px 0 rgba($color-ebony-clay, 0.6), inset 0 1px 4px 0 rgba($color-ebony-clay, 0.1);
			}
		}
		&:not(:active):focus {
			box-shadow: 0 0 0 1px $color-lumenblue-darkest, 0 1px 0 1px rgba($color-ebony-clay, 0.08),
				inset 0 1px 0 1px rgba(white, 0.08);
		}
		&[disabled] {
			background: $color-lumenblue;
			color: $color-concrete-lightest;
		}
	}

	&--slim {
		padding: $spacing-unit-xxs $spacing-unit-xs;
	}

	&--large {
		font-size: 1rem;
		padding: $spacing-unit-sm;
	}

	+ .button {
		margin-left: $spacing-unit-xs;
	}
}

a.button {
	display: inline-block;
	text-decoration: none;
	&--primary {
		&:link,
		&:visited,
		&:hover,
		&:focus,
		&:active {
			color: white;
		}
	}
}

.button-group {
	.button {
		border-radius: 0;
		&:first-child {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		&:last-child {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
		+ .button {
			border-left-color: transparent;
			margin-left: -3px;
			&:focus {
				border-left-color: $color-lumenblue;
			}
		}
	}
}

.button-toggle {
	display: inline-flex;

	&[aria-checked="true"] :first-child,
	&[aria-checked="false"] :last-child {
		background: $color-mystic;
		box-shadow: inset 0 1px 3px rgba($color-ebony-clay, 0.3);
	}

	.button {
		pointer-events: none; // allows the actual button element to be the objEvent.target
		+ .button {
			margin-left: -4px;
		}
	}
}
