.swatch-section {
  .guide-example {
    display: flex;
    flex-wrap: wrap;
  }
}
.swatch-list {
  display: flex;
  width: 70vw;
  max-width: 860px;
  @media (max-width:820px) {
    flex-wrap: wrap;
  }
}
.swatch-item {
  flex: 1 0 auto;
  font-size: 0.85rem;
  @media (max-width:820px) {
    display: flex;
    width: 100%;
  }
}
.swatch {
  height: 60px;
  @media (max-width:820px) {
    width: 120px;
  }
}

.swatch-details {
  code {
    display: block;
    padding: $spacing-unit-xs;
    text-align: center;
    @media (max-width:820px) {
      min-width: 100px;
    }
  }
}
